.EnterPhrase__Container {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: black;
  border-radius: 10px;
  position: relative;
}

.EnterPhrase__Title {
  color: black;
  font-size: 3.5rem;
  margin: 0;
  margin-bottom: 1.5rem;
}

.EnterPhrase__RoundContainer{
  position: absolute;
  top: 20px;
  left: 0;
  color: black;

  p {
    margin: 0;
  }
}

.EnterPhrase__Image {
  position: absolute;
  top: calc(50% - 100px);
  left: -210px;
  width: 200px;
  height: auto;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.EnterPhrase__Clock {
  position: absolute;
  bottom: 5px;
  right: 25px;
  width: 70px;
}

.EnterPhrase__ButtonContainer {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  align-items: center;
  gap: 0.5rem;

  p {
    color: black;
    margin: 0;
    align-self: flex-end;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.EnterPhrase__PlayerCount {
  position: absolute;
  top: 10px;
  right: 0;
  color: black;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;

  h3 {
    margin: 0;
  }
}
