.GameEnd__Container {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    background: white;
    border-radius: 10px;
    flex-direction: column;
    overflow: auto;
}

.GameEnd__NewGame {
    display: flex;
    color: white;
    font-family: 'Lilita One', cursive;
    position: relative;
    font-weight: 300;
    justify-content: center;
    align-items: center;
    height: 30px;
    cursor: pointer;
    min-width: 120px;
    background: linear-gradient(180deg, #096bfd 0%, #043784 100%);
    box-shadow: 4px 4px 0px rgba(34, 207, 253, 0.67);
    border-radius: 25px;
    white-space: nowrap;
    width: 50%;
}

.GameEnd__NextButton {
    display: flex;
    color: white;
    font-family: 'Lilita One', cursive;
    font-weight: 300;
    justify-content: center;
    align-items: center;
    height: 30px;
    cursor: pointer;
    min-width: 90px;
    background: linear-gradient(180deg, #096bfd 0%, #043784 100%);
    box-shadow: 4px 4px 0px rgba(34, 207, 253, 0.67);
    border-radius: 25px;
    white-space: nowrap;
    width: 160px;
    bottom: 8px;
    right: 8px;
    position: absolute;
    z-index: 100;
}


.GameEnd__Title {
    color: white;
}

.GameEnd__DrawingContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    position: relative;
    gap: 1rem;
}

.GameEnd__GuessContainer {
    width: 100%;
    display: flex;

    align-items: center;
    flex-direction: row;
    position: relative;
    margin: 10px 0px;
    animation: fadeslidein 1s;
}

@-webkit-keyframes fadeslidein {
    from {
        opacity: 0;
        transform: translateX(-100px);
    }

    to {
        opacity: 1;
        transform: translateX(0px);
    }
}

@-moz-keyframes fadeslidein {
    from {
        opacity: 0;
        transform: translateX(-100px);
    }

    to {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes fadeslidein {
    from {
        opacity: 0;
        transform: translateX(-100px);
    }

    to {
        opacity: 1;
        transform: translateX(0px);
    }
}

.GameEnd__AvatarContainer {
    height: 80px;
    width: 80px;
    border-radius: 15px;
    margin-left: 20px;
    margin-right: 20px;
}

.GameEnd__AvatarContainerDrawingItem {
    height: 80px;
    width: 80px;
    border-radius: 15px;
    margin-left: 10px;
    margin-right: 50px;
}

.GameEnd__CanvasContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    border-radius: 0px 0px 15px 15px
}

.GameEnd__CanvasContainerWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    background-color: #355ddb;
    padding: 30px 5px 5px 5px;
    position: relative;
    width: 40%;
    aspect-ratio: 4/3;
}

.GameEnd__PhraseContainer {
    width: 60%;
    height: 60px;
    border-radius: 15px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background-color: #355ddb;
    color: white;
    position: relative;
}

.GameEnd__PhraseOwner{
    position: absolute;
    top: 4px;
    left: 16px;
    font-family: inherit;
    font-weight: bold;

}

.GameEnd__HistoryItemContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.GameEnd__MessageTriangle::before {
    content: "";
}

.GameEnd__MessageTriangle {
    height: 20px;
    width: 20px;
    rotate: 45deg;
    position: absolute;
    left: 110px;
    z-index: 0;
    top: 40%;
    background-color: #355ddb;
}

.GameEnd__MessageTriangleDrawingItem::before {
    content: "";
}

.GameEnd__MessageTriangleDrawingItem {
    height: 20px;
    width: 20px;
    rotate: 45deg;
    position: absolute;
    z-index: 0;
    top: 50%;
    right: 146px;
    background-color: #355ddb;
}

.GameEnd__DrawingOwner {
    position: absolute;
    top: 4px;
    right: 16px;
    font-family: inherit;
    font-weight: bold;
    color: white;
}

.EndItem__Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    color: rgba(0, 0, 0, 0.4);
    margin-bottom: 10px;
}

.EndItem__DownloadContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-top: 1rem;
    margin-bottom: 1rem;

}

.EndItem__Text {
    text-align: center;
    vertical-align: center;
    margin: 0;
}

.EndItem__Line {
    width: 300px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.4);
    margin: 0px 10px;

    @media only screen and (max-width: 1400px) {
        width: 200px;
    }
}

.EndItem__Download {
    cursor: pointer;
}