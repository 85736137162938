.App__ChatContainerDrawAndGuess {
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  justify-content: space-between;
  min-width: 160px;
  width: 35%;
  height: calc(100% - 16px);
}

.App__ChatContainerBrokenPhone {
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  justify-content: space-between;
  min-width: 160px;
  width: 100%;
  height: 100%;
}

.App__ChatContainerLobby{
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  justify-content: space-between;
  min-width: 160px;
  width: 100%;
  height: 100%;
}

.App__ChatHeader {
  background-color: #f2f2f2;
  color: black;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;

  p {
    font-size: 24px;
    @media only screen and (max-width: 1400px) {
      font-size: 20px;
    }
  }
}

.App__ChatHeaderWelcomePage {
  background-color: #f2f2f2;
  color: black;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;

  p {
    font-size: 24px;
  }
}

.App_ChatMessages {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: medium;
  overflow-y: scroll;
  overflow-wrap: break-word;
  margin-bottom: auto;
}

.App_SendMessage {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.8rem;
  // margin-top: auto;
}

.Chat__From {
  color: orangered;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-family: sans-serif;
  font-size: 14px;
}

.Chat__Message {
  color: black;
  font-family: sans-serif;
  font-size: 14px;
}

.Chat__MessagesContainer {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  padding-right: 20px;
  padding-left: 20px;
}
