.App_ChangeName {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  .App_SendButton {
    margin: 15px 5px;
  }
}

.App__ChatRoomContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
  // min-height: 491.5px;
  padding: 8px;
  gap: 8px;
  justify-content: center;
  height: calc(100% - 16px);
}

.App__DrawAndGuessContainer {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px;
}

.App_WelcomeContainer {
  display: flex;
  width: 85vw;
  height: 85vh;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 20px;
  .DrawAndGuess {
    flex: 1 1 40%;
    //min-width: 230px;
    //max-width: 400px;
    //height: auto !important;
  }

  .Lobby {
    flex: 1;
  }
}

.App_WelcomeContainerBrokenPhone {
  display: flex;
    width: calc(100% - 16px);
    max-width: 1500px;
    aspect-ratio: 1616/711;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 20px;
}

.App__NameField {
  margin: 0px 10px;
}

.App_ModeSelectorContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
  cursor: default;
  background-color: #f2f2f2;
  margin: 8px 8px 0 8px;
}

.App_ModeContainer {
  flex: 1;
  text-align: center;
  cursor: default;
}

.App__LobbyContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
}
