.ErrorModalContainer {
    width: 25%;
    height: 15%;
    border-radius: 50px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    flex-direction: column;
}

.Button {
    border-radius: 10px;
}
