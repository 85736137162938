@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');

.App {
  background: url(assets/backgroundDrawbotic.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #4287f5;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

// p {
//   font-family: 'Mochiy Pop One', sans-serif;
// }

p {
  font-family: 'Lilita One', cursive;
}

#root {
  height: 100%;
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
  touch-action: pan-x pan-y;
}

html {
  //height: 100vw;
}

body {
  //min-height: 100vw;
}

header{
  //width: 100vw;
}

html, body{
  overscroll-behavior-y: none;
} 