.Header__Container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  min-height: 70px;
}

.Header__TextContainer {
  width: 85vw;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 3rem;
  padding-right: 3rem;
}

.Header__Title {
  font-size: 25px;
  font-family: 'Mochiy Pop One', sans-serif;
  font-weight: bolder;
  margin: 25px 0px;
  // cursor: pointer;
}

.Header__Logo {
  aspect-ratio: 339/113;
  height: 70px;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  cursor: default;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.Header__GameLogo {
  display: flex;
  height: 80px;
  align-self: flex-end;
  cursor: default;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.Header__Title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 5px;
  width: 50%;
}

.Header__RightContainer {
  display: flex;
  width: 220px;
  background-color: white;
  height: 100%;
}

.Header__UserContainer {
  display: flex;
  width: 100%;
  padding: 0.2rem;
  align-items: center;
}

.Header__UserNameContainer {
  font-family: 'Lilita One';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  display: flex;
  align-items: center;
  width: calc(65%);

  p {
    margin: 0 !important;
    vertical-align: middle;
    width: 100%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
  }
}

.MuiPopover-paper {
  border-radius: 0px 0px 15px 15px !important;
}

.Header__AudioButton {
  display: flex;
  cursor: pointer;
  height: 35px;
  width: 35px;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;
}

.Header__ButtonsContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.Header__LanguageSelectionItem {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0px;
  gap: 0.5rem;
}

.Header__Flag {
  height: 25px;
}

.Header__AvatarSubcontainer {
  height: 40px;
  width: 40px;
  overflow: hidden;
  border-radius: 50%;
  object-fit: contain;
}

.Header__DisconnectedIcon {
  border-radius: 25px;
  background-color: white;
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
}
