.ChangeName__ButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: white;
}

.ChangeName__Title {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Lilita One';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  p {
    margin: 0;
  }
}

.ChangeName_BodyContainer {
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
}

.ChangeName__Button {
  border-radius: 10px;
}

.ChangeName__LogoItem {
  display: flex;
  align-items: center;
  height: 150px;
}

.Intermission__Container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-content: center;
  padding: 1rem;
}

.Intermission__WaitingContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-content: center;
  padding: 1rem;

  h2 {
    font-family: "Lilita One", cursive;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    color: white;
  }
  p {
    color: white;
  }
}