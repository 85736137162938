.GuessDrawing__Container {
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow: hidden;
    background-color: white;
    position: absolute;
    aspect-ratio: 4/3;
    z-index: 1;
}

.GuessDrawing__InputContainer {
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
}

.GuessDrawing__RoundContainer{
    position: relative;
    top: 0.5rem;
    left: 0.5rem;
    background-color: #f2f2f2;
    border-radius: 25px;
    color: black;
    display: flex;
    flex-direction: row;
    height: 40px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.GuessRound__ClockContainer {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 70px;
}
