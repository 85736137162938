.ProfileModal__Container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  align-items: center;
}

.ProfileModal__Content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ProfileModal__Title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Lilita One';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  width: 165px;
  height: 30px;
  padding: 1rem;
}

.ProfileModal__Item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  gap: 0.4rem;

  h3 {
    margin: 0;
    font-family: 'Lilita One';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
  }

  p {
    font-family: sans-serif;
    margin: 0;
    font-size: 26px;
    font-style: normal;
  }
}

.ProfileModal__AvatarContainer {
  height: 144px;
  width: 144px;
  overflow: hidden;
  border-radius: 50%;
  object-fit: contain;
  margin-bottom: 1rem;
}
