.Canvas__ColorPicker {
    display: flex;
    align-items: center;
    flex-wrap: nowrap !important;
    margin: 0px;
    padding: 0px;
    height: 100%;
    gap: 0.7rem;
    justify-content: center;
    margin-left: 0.8rem;
}

.Canvas__ColorPickerOption {
    height: 1.7rem;
    width: 1.7rem;
    border-radius: 25px;
    cursor: pointer;
}

.Canvas__ColorPickerOptionSelected {
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
    border:  4px solid white;
    margin: -4px;
}