.Brush__CircleContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 25px;
    cursor: pointer;
}

.Brush__SmallCircle{
    height: 9px;
    width: 9px;
    background-color: #0755CB;
    border-radius: 50%;
    display: inline-block;
}

.Brush__MediumCircle{
    height: 14px;
    width: 14px;
    background-color: #0755CB;
    border-radius: 50%;
    display: inline-block;
}

.Brush__BigCircle{
    height: 18px;
    width: 18px;
    background-color: #0755CB;
    border-radius: 50%;
    display: inline-block;
}

.Brush__BiggestCircle{
    height: 22px;
    width: 22px;
    background-color: #0755CB;
    border-radius: 50%;
    display: inline-block;
}

.Brush__BrushContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: 100%;
    width: 30%;
    background-color: white;
}
