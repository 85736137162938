.App_ChangeName {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  border-radius: 10px;
  padding-left: 2px;
  padding-top: 3px;
  padding-bottom: 3px;

  .App_SendButton {
    margin: 15px 5px;
  }
}

.Room__Canvas {
  background-color: white;
  border: 1px solid black;
  flex: 3;
}

.App__NameField {
  margin: 0px 10px;
}

.Intermission__Container {
  text-align: center;
}

// .Room__EmojiAnim {
//     position: absolute;
//     top: 60px;
//     left: 90px;
//     z-index: 1;
//     -webkit-animation-duration: 3s;
//     animation-duration: 3s;
//     transition-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1);
//     -webkit-animation-fill-mode: both;
//     animation-fill-mode: both;
//     animation-iteration-count: 1;
//     font-size: 30px;
// }

.BrokenPhone__Button {
  display: flex;
  color: white;
  font-family: 'Lilita One', cursive;
  font-weight: 300;
  justify-content: center;
  align-items: center;
  height: 30px;
  cursor: pointer;
  min-width: 30px;
  background: linear-gradient(180deg, #096bfd 0%, #043784 100%);
  box-shadow: 4px 4px 0px rgba(34, 207, 253, 0.67);
  border-radius: 25px;
  padding: 0 0.8rem;
  white-space: nowrap;
}

.BrokenPhone__ButtonDisabled {
  display: flex;
  color: white;
  font-family: 'Lilita One', cursive;
  font-weight: 300;
  justify-content: center;
  align-items: center;
  height: 30px;
  cursor: default;
  min-width: 30px;
  background: linear-gradient(180deg, #096bfd 0%, #043784 100%);
  box-shadow: 4px 4px 0px rgba(34, 207, 253, 0.67);
  border-radius: 25px;
  padding: 0 0.8rem;
  white-space: nowrap;
  filter: grayscale(1);
}

.BrokenPhone__RenderRoundTab {
  height: calc(100%);
  width: calc(100%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.BrokenPhone__Container {
  display: flex;
  height: calc(100% - 16px);
  width: calc(100% - 16px);
  padding: 8px;
  gap: 8px;
  // aspect-ratio: 1616 / 771;
  position: relative;
}

.Players__PlayerContainerBrokenPhone {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  background-color: #ffffff;
  height: 100%;
  min-width: 280px;
  flex: 0 1 30%;
}

.App__ChatRoomContainerBrokenPhone {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.BrokenPhone__HistoryHeader {
  color: black;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;

  p {
    font-size: 24px;
    margin: 0;
    @media only screen and (max-width: 1400px) {
      font-size: 20px;
    }
  }
}

.BrokenPhoneLobby__ButtonContainer {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: none;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 14px;
  z-index: 999;
}

.BrokenPhoneLobby__HistoryContainer {
  margin-right: 8px;
  position: relative;
  display: flex;
  flex-direction: column;

  .BrokenPhoneLobby__LineContainer {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-y: scroll;
  }

  @media only screen and (max-width: 1400px) {
    width: 600px;
  }
}
