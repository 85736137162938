.Players__PlayerContainer {
  flex: 1 1 30%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  //min-width: 16vw;
  //height: 100%;
  //max-width: 260px;
  // width: 25vw;
  background-color: #ffffff;
  height: calc(100% - 16px);
  min-width: 160px;
}

.Players__PlayerHeader {
  background-color: #f2f2f2;
  cursor: default !important;
  display: flex;
  justify-content: center;
  color: black;
  min-height: 60px;
  align-items: center;
  gap: 0.5rem;
  p {
    display: flex;
    align-items: center;
    font-size: 24px;
    margin-block-end: 0;
    margin-block-start: 0;
    white-space: nowrap;
    @media only screen and (max-width: 1400px) {
      font-size: 20px;
    }
  }

}

.Players__PlayerItemContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;

  background-color: transparent;
}

.Players__PlayerItemContainer_MyUser {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 100%;
}

.Players__PlayerItem {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  display: flex;
}

.Players__Name {
  font-family: sans-serif;
  font-style: normal;
  margin: 0px 0px;

    vertical-align: middle;
    width: 100%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;

  // display: -webkit-box;
  // -webkit-line-clamp: 1; /* number of lines to show */
  // line-clamp: 1;
  // -webkit-box-orient: vertical;
  // width: 100%;
}

.Players__NameContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-overflow: ellipsis;
  gap: 0.5rem;
  position: relative;
}

.Player__Icons {
  display: flex;
  align-items: center;
  width: 25%;
  justify-content: center;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.Players__Points {
  display: flex;
  color: white;
  font-weight: 500;
  border-radius: 25px;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
}

.Players__ItemsContainer {
  flex: 1;
  display: flex;
  padding: 0.5rem;
  gap: 0.5rem;
  flex-direction: column;
  overflow-y: scroll;
  background-color: #f2f2f2;
}

.Players__Footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 60px;
  background-color: #f2f2f2;
  gap: 1rem;
  padding: 0 0.5rem 0 0.5rem;
}

.Players__AvatarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 1;
  width: 100%;
}

.Players__Avatar {
  height: 100%;
  width: 100%;
  border-radius: 100%;
  object-fit: cover;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.Players__DrawingIcon {
  font-size: 1.2rem;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.Players__AvatarSubcontainer {
  width: 45px;
  height: 45px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ffffff;
}

// .Players__AvatarSubcontainerMedium {
//   width: 40px;
//   height: 40px;
//   aspect-ratio: 1/1;
//   overflow: hidden;
//   border-radius: 50%;
//   object-fit: contain;
//   margin-right: 5px;
// }

.Players__MenuItem {
  margin: 0px 10px;
}

.Players__PenIcon {
  position: absolute;
  left: -8%;
  // right: 60%;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  height: 25px;
  width: 25px;
}

.Players__PlayerDrawing {
  box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.3);
}

.clickable {
  cursor: pointer;
}

.Players__Guess {
  font-family: sans-serif;
  font-style: normal;
  color: rgba(0, 0, 0, 0.3);
  font-size: 0.35cm;
  margin: 0px 0px;
  position: absolute;
  left: 5;
  bottom: -15px;
}