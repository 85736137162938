.CreateRoom__ButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #202a49;
  padding: 10px 20px;
}

.CreateRoom__Header {
  width: 100%;
  background-color: #f0d558;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.CreateRoom_BodyContainer {
  padding: 20px;
  margin: 0px 0px 0px 200px;
}

.CreateRoom__Button {
  border-radius: 10px;
}

.CreateRoom__LogoItem {
  position: absolute;
  top: 50%;
  left: 10px;
  width: 205px;
  height: auto;
  transform: translate(-0%, -50%);
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.CreateRoom__ButtonContainerModal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  align-items: center;
}

.CreateRoom__Logo {
  position: relative;
  height: 220px;
  // overflow: visible;
  bottom: 145px;
  left: 10px;
}

.CreateRoom__Header {
  width: 100%;
  background-color: #f0d558;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.CreateRoom_BodyContainer {
  padding: 20px;
  margin: 0px 0px 0px 200px;
}

.CreateRoom__Title {
  display: flex;
  align-items: center;
  font-family: 'Lilita One';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
}

.CreateRoom__Cancel {
  display: flex;
  align-items: center;
  height: 40px;
  width: 120px;
  background-color: white;
  border: 3px solid #0755cb;
  border-radius: 25px;
  box-shadow: 4px 4px 0px rgba(34, 207, 253, 0.67);
  justify-content: center;
  font-family: 'Lilita One', cursive;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  font-style: normal;
  color: #0755cb;
}

.CreateRoom__Buttons {
  display: flex;
  color: white;
  font-family: 'Lilita One', cursive;
  font-weight: 400;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 120px;
  cursor: pointer;
  background: linear-gradient(180deg, #096bfd 0%, #043784 100%);
  box-shadow: 4px 4px 0px rgba(34, 207, 253, 0.67);
  border-radius: 25px;
  white-space: nowrap;
}

.CreateRoom__LogoItem {
  position: absolute;
  top: 50%;
  left: 10px;
  width: 205px;
  height: auto;
  transform: translate(-0%, -50%);
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.CreateRoom__FormRow {
  display: flex;
  gap: 1rem;
  height: 65px;
}
