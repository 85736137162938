.ChangeName__Header {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Lilita One";
  font-style: normal;
  font-weight: 400;
  font-size: x-large;
}

.ChangeName__Body {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  font-family: "Lilita One";
  font-style: normal;
  font-weight: 400;
  font-size: x-large;
  height: 50%;
}

.ChangeName__Button {
  border-radius: 10px;
}

.ChangeName__LogoItem {
  display: flex;
  align-items: center;
  height: 150px;
}

.Intermission__Container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-content: center;
  padding: 1rem;
}
