.PlayerDrawing__Text {
  width: 100%;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Lilita One';
  font-style: normal;
  font-weight: 400;
  align-self: center;
  line-clamp: 1;
  p {
    margin: 0;
  }
}

.PlayerDrawing__Description {
  margin-block-end: 0;
  margin-block-start: 0;
}
