.Report__ModalContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.Report__ModalButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
    align-items: center;
    width: 100%;
}

.Report__CancelButton {
    display: flex;
    align-items: center;
    height: 40px;
    width: 120px;
    background-color: white;
    border: 3px solid #0755CB;
    border-radius: 25px;
    box-shadow: 4px 4px 0px rgba(34, 207, 253, 0.67);
    justify-content: center;
    font-family: "Lilita One", cursive;
    font-weight: 400;
    font-size: 18px;
    cursor: pointer;
    font-style: normal;
    color: #0755CB;
  }

.Report__ReportButton {
    display: flex;
    color: white;
    font-family: "Lilita One", cursive;
    font-weight: 400;
    font-size: 18px;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 120px;
    cursor: pointer;
    background: linear-gradient(180deg, #096bfd 0%, #043784 100%);
    box-shadow: 4px 4px 0px rgba(34, 207, 253, 0.67);
    border-radius: 25px;
    white-space: nowrap;
}