.AuthModal__TabsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
}

.AuthModal__Tab {
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  height: 100%;
  width: 45%;

  font-family: 'Lilita One', cursive;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  height: 45px;
  cursor: pointer;
  background: linear-gradient(180deg, #096bfd 0%, #043784 100%);
  border-radius: 25px;
  white-space: nowrap;
  color: white;
}

.AuthModal__TabOff {
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  height: 100%;
  width: 50%;

  font-family: 'Lilita One', cursive;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  height: 45px;
  cursor: pointer;
  background: white;
  border-radius: 25px;
  white-space: nowrap;
  color: #0755cb;
  border: 3px solid #0755cb;
  box-sizing: border-box;
}

.AuthModal__Tab:hover {
  font-size: 21px;
}

.AuthModal__Body {
  padding: 40px;
  margin: 0px 0px 0px 0px;
  padding-top: 10px;
}

.AuthModal__ContinueContainer {
  text-align: center;
}

.AuthModal__ContinueText {
  font-size: 18px;
  color: #202a49;
  margin-bottom: 0px;
}

.AuthModal__GuestText {
  color: red;
  cursor: pointer;
}

.AuthModal__SelectAvatarContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.AuthModal__Avatar {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.AuthModal__AvatarContainer {
  height: 100px;
  width: 100px;
  border: 1px solid grey;
  border-radius: 50%;
  overflow: hidden;
}

.AuthModal__UploadAvatarText {
  color: darkblue;
  cursor: pointer;
  margin-bottom: 5px;
}

.AuthModal__TextFieldContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  div {
    margin: 0 !important;
  }
}
