.Room__Rooms {
  flex: 0.2;
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
  min-width: 400px;
}

.Room_RoomHeader {
  display: flex;
  background-color: #f2f2f2;
  color: black;
  align-items: center;
  justify-content: center;
  flex: 0.095;
  min-height: 70px;
  max-height: 70px;
  p {
    font-size: x-large;
  }
}

.Room__RoomsItems {
  flex: 1;
  padding-top: 5px;
  padding-bottom: 5px;
  overflow-y: scroll;
  min-width: 300px;
}

.Room__RoomItemContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 0.15;
  margin: 5px 0px;
}

.Room__RoomItem {
  width: 90%;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  text-align: left;
  background-color: white;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent;
}

.Room__RoomInfoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  padding: 0px 10px;
  text-align: left;
}

.Room__RoomFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  width: 100%;
  padding: 10px 0px;
  flex: 0.05;
}

.Room__QuantityText {
  margin: 0px;
  margin-right: 10px;
  white-space: nowrap;
}

.Room__FlagContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0px 10px;
}

.Room__Flag {
  height: auto;
  width: 30px;
  margin: 0px 5px 0px 0px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.Room__Text {
  margin: 10px 0px 0px 0px;
  padding: 0px;
}

.Room__WordListText {
  margin: 0px;
  font-size: 15px;
}

.Room__Overlay {
  position: absolute;
  background-color: grey;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}

.Room__FlagLeftContainer {
  height: '100%';
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
