.Profile__Layout {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.Profile__Container {
  width: 100%;
  height: 100%;
  max-width: 1400px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.Profile__Card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70%;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  align-items: center;
}

.Profile__AvatarContainer {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin-top: 20px;
}

.Profile__Avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.Profile__UserStatsContainer {
  align-self: center;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.Profile__UserStatsColumn {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  text-align: center;
  font-size: 40px;

  p {
    margin-top: 10px;
  }
}

.Profile__ItemTitle {
  font-size: 23px;
  font-weight: bold;
  margin-bottom: 1px;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  align-self: center;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Profile__UserLanguage {
  display: flex;
  justify-content: center;
  align-items: center;
}
