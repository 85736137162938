.Canvas__CanvasContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background-color: white;
  height: 100%;
  aspect-ratio: 4/3;
  // border: 1px solid black;
  // border-radius: 10px;
}

.Canvas__Canvas {
  background-color: white;
  flex: 2;
}

.Canvas__BrushOptions {
  max-width: 1000px;
}

.Canvas__Header {
  min-height: 70px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: white;

  p {
    margin: 0px 5px;
  }
}

.Canvas__BlurHeader {
  position: absolute;
  width: 100%;
  left: 0;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.7);
}

.Canvas__Button {
  margin: 0px 3px;
  font-size: medium;
  border-radius: 5px;
  background-color: #566380;
  color: white;
}

.Canvas__WordContainer {
  background-color: #fee124;
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-weight: bold;
  font-size: 25px;
  color: black;
  letter-spacing: 2px;
}

.Canvas__ColorContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.Canvas__OptionsContainer {
  background-color: #f2f2f2;
  border-radius: 25px;
  padding: 0 1rem 0 1rem;
  color: black;
  display: flex;
  flex-direction: row;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Canvas__ButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 5px;
  background-color: white;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.Canvas__TrashButton{
  margin-right: 1rem;
}

.Canvas__ButtonContainerWarning {
  display: flex;
  color: white;
  font-family: 'Lilita One', cursive;
  font-weight: 300;
  justify-content: center;
  align-items: center;
  height: 30px;
  cursor: pointer;
  min-width: 30px;
  background: linear-gradient(180deg, #096bfd 0%, #043784 100%);
  box-shadow: 4px 4px 0px rgba(34, 207, 253, 0.67);
  border-radius: 25px;
  padding: 0 0.8rem;
  white-space: nowrap;
}

.Canvas__ButtonContainerLeave{
  position: absolute;
  display: flex;
  color: white;
  font-family: 'Lilita One', cursive;
  font-weight: 300;
  justify-content: center;
  align-items: center;
  height: 30px;
  cursor: pointer;
  min-width: 30px;
  background: linear-gradient(180deg, #096bfd 0%, #043784 100%);
  border-radius: 20px;
  padding: 0 0.8rem;
  white-space: nowrap;
  top: 10px;
  right: 10px;
}

.Canvas__ButtonContainerWarningDisabled {
  pointer-events: none;
  display: flex;
  color: white;
  filter: grayscale(100%);
  font-family: 'Lilita One', cursive;
  font-weight: 300;
  justify-content: center;
  align-items: center;
  height: 30px;
  cursor: pointer;
  min-width: 30px;
  background: linear-gradient(180deg, #096bfd 0%, #043784 100%);
  box-shadow: 4px 4px 0px rgba(34, 207, 253, 0.67);
  border-radius: 25px;
  padding: 0 0.8rem;
  white-space: nowrap;
}

.Canvas__ButtonBrokenPhone {
  color: white !important;
  width: 260px;
}

.Canvas__WordPanelContainer {
  display: flex;
  flex-direction: row;
  height: 40px;
  align-items: center;
  justify-content: center;
  min-width: 50%;
  gap: 0.5rem;
}

.Canvas__DrawingTools {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.Canvas__DrawingOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  background-color: #f2f2f2;
  position: absolute;
  height: 55px;
  border-radius: 25px;
  top: 50px;
}

//FADE IN
.Canvas__VisibleToolbar {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.1s linear;
}

//FADE OUT
.Canvas__HiddenToolbar {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.1s, opacity 0.1s linear;
}

.Canvas__RoundsCounter {
  display: flex;
  align-items: center;
}

.Canvas__ToolButtonsContainer {
  display: flex;
  gap: 0.4rem;
}

.Canvas__DrawingToolsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.8rem;
}

.Canvas__Disable {
  pointer-events: none;
  // opacity: 0.5;
  //filter: grayscale(100%);
  // //text-shadow: 0 0 0 gray;
}

.Canvas__EmojiReactionContainer {
  display: flex;
  align-items: flex-end;
}

.Canvas__EmojiReactionContainer:first-of-type {
  border-radius: 20px;
  z-index: 8;
}

.Canvas__CanvasButtons {
  display: flex;
  position: absolute;
  bottom: 5px;
  right: 0;
  align-items: center;
  justify-content: space-between;
  width: 54%;
}

.Canvas__TimeRemaining {
  position: relative;
  // bottom: 3px;
  // right: 5px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 55px;
  z-index: 10;
  font-family: 'Lilita One';
  font-style: normal;
  font-weight: 400;
  background-color: white;
}

.Canvas__ReactionContainer {
  display: flex;
  align-items: center;
  border-radius: 15px;
  padding: 0.3rem 0.4rem;
  position: absolute;
  bottom: 15px;
  left: 15px;
  font-size: 20px;
  gap: 0.1rem;

  .MuiCollapse-wrapperInner {
    display: flex;
  }
}
.Canvas__Word {
  font-size: 1.2vw;
}
.Canvas__MuteButton {
  height: 25px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.Canvas__ReportButton {
  display: flex;
  align-items: center;
  height: 40px;
  width: 100px;
  background-color: white;
  border: 3px solid #0755cb;
  border-radius: 25px;
  justify-content: center;
  font-family: 'Lilita One', cursive;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  font-style: normal;
  color: #0755cb;
}

.Canvas__SandClock {
  img {
    opacity: 1;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }
}

.Canvas__CanvasHeaderButton {
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  height: 40px;
  cursor: pointer;
  background: linear-gradient(180deg, #096bfd 0%, #043784 100%);
  box-shadow: 4px 4px 0px rgba(34, 207, 253, 0.67);
  border-radius: 25px;
  padding: 0 1rem;
  white-space: nowrap;
  margin-right: 5px;

  p {
    font-family: 'Lilita One', cursive;
    font-weight: 400;
    font-size: 18px;
  }
}

.Canvas__CanvasHeaderButtonDisabled {
  cursor: default;
  background: #999999;
  opacity: 0.5;
  box-shadow: 4px 4px 0px #cfcfcf;
}

.Canvas__CanvasHeaderButtonHidden {
  visibility: hidden;
}

.Canvas__ReportButtonDisabled {
  cursor: default;
  opacity: 0.5;
  background: #999999;
  color: #3d3d3d;
  box-shadow: 4px 4px 0px #cfcfcf;
  border: 3px solid #3d3d3d;
}

.Canvas__Confetti {
  height: 100%;
  width: 100%;
  z-index: 100;
}

.Canvas__BrokenPhoneReadyButton {
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  cursor: pointer;
  background: linear-gradient(180deg, #096bfd 0%, #043784 100%);
  box-shadow: 4px 4px 0px rgba(34, 207, 253, 0.67);
  border-radius: 25px;
  padding: 0 1rem;
  white-space: nowrap;
  margin-right: 5px;
  width: 120px;

  span {
    font-family: 'Lilita One', cursive;
    font-weight: 400;
    font-size: 18px;
  }
}