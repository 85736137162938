.DownloadAppModal__Badges {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        border: 1px solid white;
        border-radius: 6px;
        box-sizing: border-box;
        height: 85px;
    }

    #iOs{
        height: 60px;
    }
}