.GameEnd__Header {
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.GameEnd__BodyContainer {
  padding: 20px;
}

.GameEnd__PodiumContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  flex: 1;
  margin: 20px 0px;
}

.GameEnd__PodiumItemBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0px;
  width: 140px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin: 0px 1px;
}

.GameEnd__PodiumItemContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.GameEnd__PlayerName {
  margin-top: 5px;
  margin-bottom: 0px;
  font-family: 'Arial';
  font-weight: bold;
  color: white;
}

.GameEnd__PlayerScore {
  margin-top: 5px;
  font-family: 'Arial';
  color: white;
}

.GameEnd__Avatar {
  width: 80%;
  aspect-ratio: 1;
  margin: 0px;
  margin-bottom: 8px;
  border-radius: 50%;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.GameEnd__Position1 {
  position: absolute;
  left: 39%;
  transform: translate(-50%, -50%);
  top: 30.3%;
  height: 85px;
}

.GameEnd__Position1_2 {
  position: absolute;
  left: 46.5%;
  transform: translate(-50%, -50%);
  top: 30.3%;
  height: 85px;
}

.GameEnd__Position1Score {
  position: absolute;
  left: 30%;
  top: 66%;
  font-weight: bold;
  color: white;
  width: 124px;
  text-align: center;
}

.GameEnd__Position1Score_2 {
  position: absolute;
  // right: 44%;
  left: 38%;
  top: 66%;
  font-weight: bold;
  color: white;
  width: 124px;
  text-align: center;
}

.GameEnd__Position1Name {
  position: absolute;
  left: 32%;
  top: 62.5%;
  font-weight: bold;
  color: white;
  width: 100px;
  text-align: center;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.GameEnd__Position1Name_2 {
  position: absolute;
  // right: 44%;
  left: 40%;
  top: 61.5%;
  font-weight: bold;
  color: white;
  width: 100px;
  text-align: center;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.GameEnd__Position3Score {
  position: absolute;
  left: 49%;
  top: 66%;
  font-weight: bold;
  color: white;
  width: 120px;
  text-align: center;
}

.GameEnd__Position3Name {
  position: absolute;
  left: 50%;
  top: 62.5%;
  font-weight: bold;
  color: white;
  width: 100px;
  text-align: center;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.GameEnd__Position2Score {
  position: absolute;
  left: 12%;
  top: 66%;
  font-weight: bold;
  color: white;
  width: 120px;
  text-align: center;
}

.GameEnd__Position2Score_2 {
  position: absolute;
  left: 20.5%;
  top: 66%;
  font-weight: bold;
  color: white;
  width: 120px;
  text-align: center;
}

.GameEnd__Position2Name {
  position: absolute;
  left: 12%;
  top: 62.5%;
  font-weight: bold;
  color: white;
  width: 120px;
  text-align: center;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.GameEnd__Position2Name_2 {
  position: absolute;
  left: 20%;
  top: 61.5%;
  font-weight: bold;
  color: white;
  width: 120px;
  text-align: center;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.GameEnd__Position2 {
  position: absolute;
  left: 20.5%;
  transform: translate(-50%, -50%);
  top: 39%;
  height: 75px;
}

.GameEnd__Position2_2 {
  position: absolute;
  left: 28.7%;
  transform: translate(-50%, -50%);
  top: 39%;
  height: 75px;
}

.GameEnd__Position3 {
  position: absolute;
  left: 56%;
  transform: translate(-50%, -50%);
  top: 44.8%;
  height: 65px;
}

.GameEnd__ModalImageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.GameEnd__EndModal {
  width: 56%;
  padding-right: 15px;
  aspect-ratio: 468/320;
  padding-top: 1rem;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.GameEnd__ModalPlayerPodium {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.GameEnd__PlayerPodium {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  color: white;
  align-items: center;
  width: 120px;
}

.GameEnd__Text {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 0;
  }

  .GameEnd__PodiumPlace {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
  }

  .GameEnd__Name {
    width: 120px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
  }
}
