.App_ChangeName {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  border-radius: 10px;
  padding-left: 2px;
  padding-top: 3px;
  padding-bottom: 3px;

  .App_SendButton {
    margin: 15px 5px;
  }
}

.Room__Canvas {
  background-color: white;
  border: 1px solid black;
  flex: 3;
}

.App__NameField {
  margin: 0px 10px;
}

.Intermission__CreateRoomContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 2.5rem;
  gap: 0.5rem;
}

.Room__CanvasContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible;
  height: calc(100% - 16px);
  aspect-ratio: 4/3.2;
}

.Room__EmojiAnim {
  display: inline-flex;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  z-index: 100;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  transition-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  font-size: 40px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  99% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

//emoji reaction
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  80% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translateY(-95%);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  80% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translateY(-95%);
  }
}

@keyframes leftRight {
  0% {
    left: 0px;
  }

  25% {
    left: 20px;
  }

  50% {
    left: 0px;
  }

  75% {
    left: -20px;
  }

  100% {
    left: 0px;
  }
}

@keyframes rightLeft {
  0% {
    left: 0px;
  }

  25% {
    left: -20px;
  }

  50% {
    left: 0px;
  }

  75% {
    left: 20px;
  }

  100% {
    left: 0px;
  }
}

.Canvas__EmojiContainer {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  z-index: 10;
  height: 100%;
  align-items: flex-end;
}

.Canvas__Emoji {
  width: 2rem;
  height: 2rem;
}

.Canvas__FirstEmoji {
  border-radius: 20px;
}

.Canvas__EmojiAnim {
  display: inline-flex;
  align-items: flex-end;
  position: absolute;
  left: 3px;
  justify-content: center;
  width: 27.47px;
  height: 100%;
  z-index: 5;
}

.Emoji__AnimLeftRight {
  // cubic-bezier(0.5, -5, 0.5, 5)
  animation: leftRight 2.9s linear;
}

.Emoji__AnimLeftRight2 {
  animation: rightLeft 2.9s linear;
}

.Emoji__Anim {
  animation: fadeOutUp 3s;
}

.Canvas__EmojiAux {
  position: absolute;
  filter: grayscale(100%);
  z-index: 999;
}

.Canvas_DisplayNone {
  display: none;
  position: absolute;
}
